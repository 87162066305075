//
// services.scss
//
#solusi {
    transition-timing-function: cubic-bezier(.175,.885,.32,1.7);
    background-color: #FFF1F1 !important;
    img{
        height: 105px !important;
        width: 105px !important;
    }
    .justify-content-center{
        h2{
            color: $black;
            font-size: 34px;
            font-weight: 600;
            line-height: 50px;
        }
        p{
            color: $black !important;
            font-size: 22px;
            font-weight: 500;
            line-height: 34px;
        }
    }   
    .color-border{  
        padding: 5px;
        border-radius: 28px;
        height: 100% !important;
        background-image: linear-gradient(to right bottom, #FF5C30, #B0B0B0);
    }
}
.service-box {
    margin: auto;
    transition: all 0.5s;
    border-radius: 28px;
    .service-icon-bg {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .service-box-icon {
            display: flex;
            height: 100%;
            align-items: center;
            filter: brightness(0.55);
            transition: all 0.5s;
        }
    }
    .service-title, .read-more {
        color: black;
        font-size: 22px;
        font-weight: 500;
        line-height: 34px;
    }
    .service-subtitle {
        color: $black;
        font-size: 11px;
        line-height: 17px;
    }
    // &:hover {
    //     background: $primary;
    //     border-color: $primary;
    //     .service-box-icon {
    //         filter: brightness(1);
    //     }
    //     .service-title, .read-more, .service-subtitle, .service-box-icon {
    //          transition: all 0.5s;
    //      }
    //      .service-title, .read-more {
    //         color: $white;
    //     }
    //     .service-subtitle {
    //         color: rgba($white, 0.5);
    //     }
    // }
}

@media (min-width: 700px) and (max-width:900px){
    #solusi{
        padding: 50px 0;
        img{
            height: 150px !important;
            width: 150px !important;
        }
        h2{
            font-size: 28px;
            line-height: 40px;
        }
        p{
            width: 90%;
            margin: auto;
            margin-top: 25px;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 70px;
        }
        .color-border{
            width: 560px;
            margin: auto;
            margin-bottom: 25px;
        }
        .service-box{
            width: 550px;
            height: 400px;
            padding:20px;
            .service-title, .read-more {
                font-size: 25px !important;
                line-height: 35px !important;
            }
            .service-subtitle {
                font-size: 15px !important;
                line-height: 30px !important;
            }
        }
    }
}

@media only screen and (max-width: 480px){ 
    #solusi{
        padding: 30px 0;
        img{
            height: 100px !important;
            width: 100px !important;
        }
        h2{
            font-size: 28px !important;
            line-height: 40px !important;
        }
        p{
            font-size: 20px !important;
            line-height: 30px!important;
        }
        .color-border{
            width: 310px;
            margin: auto;
        }
        .service-box{
            width: 300px;
            height: 320px;
            padding:20px;
            .service-title, .read-more {
                font-size: 15px !important;
                line-height: 30px !important;
            }
            .service-subtitle {
                font-size: 11px !important;
                line-height: 20px !important;
            }
        }
    }
}