// 
// Hero
// 

.about{
    color: $black;
    h2{
        font-size: 34px;
        font-weight: 600;
        line-height: 50px;
    }
    p{
        font-size: 22px;
        font-weight: 500;
        line-height: 34px;
    }
    .about-image{
        position: relative;
        top: -50px;
        height: 275px;
        width: 100%;
        img{
            display: block !important;
            position: absolute;
            width: 100%;
        }
    }
}

@media (min-width: 480px ) and (max-width: 900px) {
    .about{
        margin-top: -300px;        
        padding: 75px 0;
        h2{
            font-size: 28px;
            line-height: 40px;
        }
        p{
            margin-top: 20px;
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 120px;
        }
        .about-image{
            position: relative;
            top: -50px;
            height: 275px;
            width: 100%;
            img{
                padding-left: 10%;
                padding-right: 10%;
                position: absolute;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .about{
        margin-top: -270px;
        padding: 30px 10px;
        h2{
            font-size: 28px;
            line-height: 40px;
        }
        p{
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 100px;
        }
    }
}