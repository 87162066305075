#register{
    padding: 0;
    position: relative;
    background: $white;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    img{
        position: absolute;
        width: 55.6%;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
    }
    .separator{
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 2;
        img{
            position: static;
            height: auto;
            width: 40.2%;
        }
        .form{
            // padding-top: 5.6%;
            background-color: $white;
            height: 100%;
            width: 53.2%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h1{
                font-weight: 600;
                font-size: 33.6px;
                line-height: 50px;
                margin-bottom: 35px;
            }
            form{
                z-index: 3;
                *{
                    margin-bottom: 14px;
                    display: block;
                }
                label{
                    font-weight: 500;
                    font-size: 16.8px;
                    line-height: 25.2px;
                }
                input[type=text]{
                    color: #818181;
                    font-weight: 500;
                    font-size: 11.2px;
                    line-height: 16.8px;
                    padding: 19px 21px;
                    width: 490px;
                    height: 55px;
                    border: 3px solid #818181;
                    border-radius: 0 17.5px 17.5px 17.5px;
                }
                input[type=submit]{
                    margin-top: 35px;
                    border: none;
                    border-radius: 0px 17.5px;
                    background-color: #FF5C30;
                    width: 490px;
                    height: 70.7px;
                    color: $white;
                }
                input[type=submit]:disabled {
                    cursor: not-allowed;
                    opacity: 0.7;
                }
                .cb{
                    display: flex;
                    align-items: center;
                }
                #check{
                    accent-color: #FF5C30 !important;
                    width: 20.3px !important;
                    height: 20.3px;
                    margin-right: 14.7px;
                }
            }
        }
    }
}

@media screen and (max-width:960px){

    #register {
        .separator{
            justify-content: center !important;
        }    
        .form{
            justify-content: center;
        }
        img{
            display: none;
        }
    }
    
}

@media screen and (max-width:560px){
    .form{
        width: 90% !important;
        input{
            width: 100% !important;
        }
    }
}