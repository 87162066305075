// 
// Hero
// 

@mixin hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

// Hero 1
.hero-1-bg {
    padding: 200px 0 180px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(255, 213, 205, 0.5);
    width: 100%;
    .half{
        position: relative;
        z-index: 1;
        width: 55%;
    }
    h1{
        font-size: 36px;
        font-weight: 600;
        line-height: 50px;
        color: $black;
    }
    p{
        font-size: 22px;
        line-height: 34px;
        font-weight: 500;
        color: $black;
    }
    .hero-image {
        z-index: 0;
        position: absolute;
        top: 110px;
        right: 0;
        width: 800px !important;
    }
    .wave{
        height: 100px;
        background: rgba(255, 213, 205, 0.5);
        filter: blur(35px);
    }
    .wave-image{
        position: absolute;
        top: 525px;
        width: 100%;
        z-index: 0;
        filter: blur(1px);
    }
}

// Hero 2
.hero-2-bg {
    padding: 250px 0 340px;
    .hero-2-overlay {
        @include hero-overlay;
    }
    .hero-2-title {
        font-size: 48px;
        text-shadow: 1px 3px 5px rgba($black, 0.15);
    }
}

// Hero 3
.hero-3-bg {
    padding: 210px 0 380px;
    .hero-3-title {
        font-size: 44px;
        text-shadow: 1px 3px 5px rgba($black, 0.15);
    }
}

// Hero 4
.hero-4-bg {
    padding: 220px 0 180px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    .hero-4-title {
        font-size: 46px;
    }
}

// Hero 5
.hero-5-bg {
    padding: 100px 0 80px;
    overflow: hidden;
    .hero-5-title {
        font-size: 44px;
        text-shadow: 1px 3px 5px rgba($black, 0.15);
    }
    .Subcribe-form {
        form {
            position: relative;
            margin: 0px auto;
        }
        input {
            padding: 12px 20px;
            min-width: 290px;
            height: 46px;
            font-size: 17px;
            color: $muted !important;
            outline: none !important;
            position: relative;
        }
    }
}

@media (min-width: 480px ) and (max-width: 900px) {
    .hero-1-bg {
        padding: 150px 0 180px;
        margin-bottom: 75px;
        .hero-image{
            top: 140px;
            height: 406px;
            width: 65% !important;
        }
        h1{
            font-size: 28px;
            line-height: 40px;
        }
        p{
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
        }
        .btn{
            font-size: 23px;
            line-height: 30px;
            padding: 8px 12px;
        }
        .wave{
            margin-top: 40px;
            height: 100px;
            filter: blur(35px);
        }
        .wave-image{
            position: absolute;
            top: 390px;
            width: 100%;
            z-index: 0;
            filter: blur(1px);
        }
    }
}

@media only screen and (max-width: 480px) {
    body{
        max-width: 100%;
    }
    .hero-1-bg {
        .half{
            width: 100%;
        }
        h1{
            width: 100%;
            margin-top: 200px;
            font-size: 28px;
            line-height: 35px;
        }
        p{
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
        }
        .btn{
            font-size: 23px;
            line-height: 30px;
            padding: 8px 12px;
        }
        .wave{
            background: none;
        }
        .wave-image{
            position: absolute;
            top: 525px;
            width: 100%;
            z-index: 0;
            filter: blur(1px);
        }
    }
}



