//
// footer.scss
//

.footer {    
    img{
        display: block !important;
    }
    // max-height: 293px !important;
    color: $white !important;
    padding: 21px 0 25px;
    background:  #FF3700;
    background-position: bottom center;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    margin: 0;
    .container{
        // max-height: 263px !important;
        overflow: hidden;
    }
    .logo-cover{
        width: 200px;
        height: 75px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .logo{
            width: 200px;
        }
    }
    .logo-horizontal{
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 40px;
        margin-top: 40px !important;
        .social-logo{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            height: 33px;
            width: 33px;
            border-radius: 100px;
            img{
                display: block !important;
                height: 16px !important;
                width: 16px !important;
            }
        }
    }
    p{
        font-size: 11px;
        line-height: 17px;
        font-weight: 500;
    }
    h4{
        font-size: 22px;
        font-weight: 500;
        line-height: 34px;
    }
    .footer-sub-menu {
        li {
            text-align: end;
            padding: 6px 0;
            .footer-link {
                width: 100%;
                color: $white;
                font-size: 17px;
                line-height: 25px;
                font-weight: 500;
            }
        }
    }
    .copyright{
        margin-top: -30px;
        background-color: transparent;
        color: #A62400 !important;
        font-weight: 600;
        font-size: 11px;
        line-height: 17px;
    }
    .row{
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .additional-footer-links {
        margin-bottom: 30px; /* Adjust this value as needed */
    }
      
}

@media (min-width:700px) and (max-width:900px){
    p{
        font-size: 15px !important;
        line-height: 20px!important;
    }
    .footer{
        li{
            font-size: 10px;
        }
    }
}
@media (max-width:993px){
    .footer{    
        text-align: center!important;
        .logo-cover {
            width: 100%;
   
            img {
                margin: 0 auto!important;
            }
        }

        .logo-horizontal {
            display: flex;
            justify-content: center!important;
            
        }
       
        .content-footer {
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .logo-pse-wrapper {
            width: 100%!important;
            display: flex;
            justify-content: center;
        }

        .copyright {
            margin-top: 30px;
        }

        .footer-link{
            font-size: 14px !important;
            line-height: 15px !important;
        }
    }
}