//
// menu.scss
//

.nav-item {
    .nav-link {
        display: inline-block;
        color: $dark;
        text-decoration: none;
    }
}
.navbar {
    .menu-social-icon {
        li {
            margin: 0 8px;
            .menu-social-link {
                color: $muted;
                transition: all 0.5s;
                &:hover {
                    color: $primary;
                }
            }
        }
        
    }
}


.navbar-custom {
    min-height: 75px;
    position: relative;
    padding: 14px 0px;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    transition: all 0.5s ease-in-out;
    background-color: #FF3700;
    img{
        display: block !important;
        position: absolute;
        left: 7.5%;
        top: -65%;
        height: 175px;
        width: auto;
    }
    .logo {
        .logo-dark {
            display: none;
        }
        .ligo-light {
            display: inline-block;
        }
    }
    
    .navbar-nav {
        li {
            a {
                line-height: 26px;
                color: $white;
                font-size: 16px;
                font-weight: 600;
                transition: all 0.3s;
                background-color: transparent !important;
                padding: 6px 0;
                margin: 0 18px;
            }
            .nav-link {
                padding-right: 0px;
                padding-left: 0px;
            }
        }
        // , li a:hover::after, li a:active::after{
        li.active a::after{
            content: '';
            display: block;
            border:  3px solid #FFFFFF;
            width: 31px;
            margin: auto;
            margin-top: 3px;
            border-radius: 10px;
        }
        &.menu-social-icon {
            .nav-item {
                .nav-link {
                    margin: 0 10px !important;
                }
            }
        }
    }
    .navbar-toggles {
        padding: .25rem .75rem;
        font-size: 18px;
        border: 1px solid transparent;
        color: $white;
        outline: 0;
    }
}

.navbar-toggler {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: $white;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    border-color: $primary;
}

.menu-toggle {
    padding: 4.5px 10px !important;
    span {
        line-height: 27px;
    }
}


// NAVBAR LIGHT

.navbar-light {
    .navbar-nav {
        li {
            a {
                color: rgba($white, 0.6) !important;
            }
        }
        li.active a, li a:hover, li a:active {
            color: $white !important;
        }
    }
    .menu-social-icon {
        li {
            margin: 0 8px;
            .menu-social-link {
                color: rgba($white, 0.6) !important;
                transition: all 0.5s;
                &:hover {
                    color: $white !important;
                }
            }
        }
        
    }
    .logo {
        .logo-dark {
            display: none !important;
        }
        .logo-light {
            display: inline-block !important;
        }
    }
}


/*---NAVBAR STICKY--*/

.nav-sticky {
    &.navbar-custom {
        margin-top: 0px;
        background-color: $white;
        box-shadow: 0 0 10px 0 rgba($black, 0.06);
        color: $black !important;
        .navbar-toggles {
            padding: .25rem .75rem;
            border: 1px solid transparent;
            outline: 0;
        }
        .navbar-nav {
            margin-top: 0px;
            li {
                a {
                    color: darken($muted, 6%) !important;
                }
            }
            li.active a, li a:hover, li a:active {
                color: $primary !important;
            }
        }
    }
    
    .logo {
        .logo-dark {
            display: inline-block !important;
        }
        .logo-light {
            display: none !important;
        }
    }
}

.navbar-toggler {
    &:focus {
        outline: none;
    }
}


.nav-register{
    border: 2px solid white;
    border-radius: 10px;
}


@media (min-width: 200px) and (max-width: 998px) {
    .navbar-custom {
        margin-top: 0px;
        // background-color: rgba(255, 55, 0, 0.8) !important;
        box-shadow: 0 3px 10px rgba($black, 0.08);
        color: $white !important;
        .navbar-nav {
            margin-top: 10px;
            li {
                a {
                    transition: all 0.4s;
                    color: $white !important;
                    margin: 0px;
                }
                &.active {
                    a {
                        border-color: transparent;
                    }
                }
            }
            li.active a, li a:hover, li a:active {
                color: $primary !important;
            }
        }
        &>.container {
            width: 90%;
        }
        .logo {
            .logo-dark {
                display: inline-block !important;
            }
            .logo-light {
                display: none !important;
            }
        }
        &.navbar-light {
            .navbar-nav {
                li.active a, li a:hover, li a:active {
                    color: $dark !important;
                }
            }
        }
        
    }
    .navbar {
        .menu-social-icon {
            li {
                margin: 0 8px;
                .menu-social-link {
                    color: $muted !important;
                    transition: all 0.5s;
                    &:hover {
                        color: $primary !important;
                    }
                }
            }
        }
        .svg-inline--fa{
            // display: block;
            color: white;
        }
    }
    .nav-sticky {
        &.navbar-custom {
            .navbar-nav{
                margin-top: 10px;
            }   
        }
    }
    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: $dark;
    }
    .navbar-collapse {
        padding: 0 20px;
        .navbar-nav {
            li.active a, li a:hover, li a:active {
                color: white !important;
            }
            li.active a::after{
                margin: 0;
            }

            .nav-register {
                border: none!important;
            }
        }
        
    }
    .navbar-light {
        .nav-item {
            .nav-link:after {
                background: $dark;
            }
        }
    }
}


