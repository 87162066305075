/* Global Styles */
html, body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Mencegah scroll horizontal */
}

/* Header */
.privacy-header {
  background-color: #ffffff;
  padding: 1.5rem 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
}

/* Introduction Section */
.privacy-intro {
  background: #F2643E;
  color: #ffffff;
  padding: 5rem 10%;
  text-align: center;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
}

.privacy-intro h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.privacy-intro p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

/* Form Section */
.privacy-form {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%;
  padding: 2rem 5%;
  margin-top: 2rem;
  font-family: inherit;
  box-sizing: border-box;
}

.privacy-form .ant-card {
  padding: 20px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.privacy-form .rounded-input {
  border-radius: 15px;
  width: 100%;
}

.privacy-form .privacy-note {
  background: #ffffff;
  color: #212529;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  box-sizing: border-box;
}

.privacy-form .privacy-note ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.privacy-form .privacy-note li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

/* Footer */
.privacy-footer {
  background: #F2643E;
  color: #ffffff;
  padding: 1.5rem 5%;
  text-align: center;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
}

.privacy-footer p {
  margin-bottom: 0;
}

/* Responsive Design */

/* For tablets and smaller devices (max-width: 768px) */
@media screen and (max-width: 768px) {
  .privacy-header {
    padding: 1rem 5%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .privacy-intro {
    padding: 4rem 5%;
  }

  .privacy-intro h1 {
    font-size: 2rem;
  }

  .privacy-intro p {
    font-size: 1rem;
  }

  .privacy-form {
    padding: 1.5rem 5%;
    margin-top: 1.5rem;
    box-shadow: none;
  }

  .privacy-form .ant-card {
    box-shadow: none;
  }

  .privacy-form button {
    width: 100%;
  }
}

/* For small devices (max-width: 480px) */
@media screen and (max-width: 480px) {
  .privacy-header {
    padding: 1rem 5%;
    flex-direction: column;
    text-align: center;
  }

  .privacy-intro {
    padding: 3rem 5%;
  }

  .privacy-intro h1 {
    font-size: 1.8rem;
  }

  .privacy-intro p {
    font-size: 0.9rem;
  }

  .privacy-form {
    padding: 1rem;
    margin: 1rem;
  }

  .privacy-form .privacy-note {
    padding: 1rem;
  }

  .privacy-footer {
    padding: 1rem;
    font-size: 0.9rem;
  }
}
