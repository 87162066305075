#promo{
    position: relative;
    width: 100%;
    img{
        // position: absolute !important;
    }
    .img-wrap{
        margin-top: 120px;
        position: relative;
        // padding: 258px 0 0 0;
        // margin: auto;
        // width: 1366px;
        // height: 795px;
        // position: relative;
       
        // .lingkaran-1{
        //     width: 35px;
        //     height: 35px;
        //     top: 128px;
        //     left: 149px;
        // }
        // .hiasan-kiri-atas{
        //     left: 79px;
        //     top: 215px;
        //     width: 161px;
        //     height: 89px;
        //     z-index: 1 !important;
        // }
        // .hiasan-kiri-bawah{
        //     left: 79px;
        //     top: 311px;
        //     width: 161px;
        //     height: 89px;
        //     z-index: 1 !important;
        // }
        // .container{
        //     position: relative;
        //     width: 1085px;
        //     height: 312px;
        //     border-radius:15px;
        //     z-index: 10 !important;
        //     background-color: white;
        //     .cover-hp{
        //         position: absolute;
        //         left: 39px;
        //         top: -76px;
        //         z-index:11;
        //     }
        //     .hp{
        //         position: relative;
        //         height: 423px;
        //         width: 243px;
        //         img{
        //             position: absolute;
        //             left: 50%;
        //             top: 50%;
        //             transform: translate(-50%, -50%);
        //         }
        //         .cover{
        //             width: 227px;
        //         }
        //         .white{
        //             width: 212px;
        //         }
        //         .logo{
        //             width: 212px;
        //         }
        //     }
        //     h2{
        //         color: $black;
        //         position: absolute;
        //         left: 338px;
        //         top: 68px;
        //         font-weight:600;
        //         font-size: 45px;
        //         line-height: 67px;
        //     }
        //     .app-store{
        //         top: 233px;
        //         left: 310px;
        //         width: 312px;
        //         height: 104px;
        //     }
        //     .google-play{
        //         top: 236px;
        //         left: 666px;
        //         width: 339px;
        //         height: 101px;
        //     }
        //     .hiasan-pojok{
        //         height: 55px;
        //         width: 55px;
        //         bottom: 0;
        //         right: 0;
        //     }
        // }
        // .lingkaran-3{
        //     right:99px;
        //     width: 10px;
        //     height: 10px;
        // }
        // .L-3-1{
        //     top: 300px;
        // }
        // .L-3-2{
        //     top: 325px;
        // }
        // .L-3-3{
        //     top: 350px;
        // }
        // .hiasan-kanan{
        //     transform: rotate(-90deg);
        //     top: 325px;
        //     right: -20px;
        //     height: 193px;
        //     width: 403px;
        // }
        // .hiasan-kanan-bawah{
        //     transform: scaleX(-1);
        //     height: 36px;
        //     width: 71px;
        //     top: 254px;
        //     right: 207px;
        //     z-index: 12;
        // }
        // .hiasan-bawah{
        //     top: 504px;
        //     left: 259px;
        //     height: 123px;
        //     width: 338px;
        //     z-index: 1;
        // }
        .download-apps {
            position: absolute;
            width: 96vw;
            // z-index: 100;
          
        }
        .lingkaran{
            // height: 557px;
            // position: absolute;
            width: 100% !important;
            // bottom:0;
            // top: 243px;
        }
    }
   
}


@media(max-width:960px){
    #promo{      
        .img-wrap{
            margin-top: 50px;
        }
        // .img-wrap{
        //     padding: 100px 0;
        //     width: 90% !important;
        //     // height: auto;
        //     height: 500px;
        //     .container{
        //         width: 100%;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         h2{
        //             position: static;
        //             font-weight: 500;
        //             font-size: 34px;
        //             line-height: 50px;
        //             text-align: center;
        //         }
        //     }
        // }
    }
}