#news{
    color: $black !important;
    h2{
        font-size: 34px !important;
        font-weight: 600 !important;
        line-height: 50px !important;
    }
    .pengguna-cover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 36px !important;
    h4{
        margin-top: 14px;
        font-weight: 500;
        font-size: 22px ;
        line-height: 34px ;
    }
    p{
        margin-top: 7px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }
}
}

@media (min-width:700px) and (max-width: 900px){
    #news{
        padding: 50px 0;
        h2{
            font-size: 28px !important;
            line-height: 40px !important;
        }
        h4{
            font-size: 25px !important;
            line-height: 40px!important;
        }
        p{
            font-size: 20px !important;
            line-height: 20px!important;
        }
        img{
            width: 200px;
            height: 200px;
        }
    }
}

@media only screen and (max-width: 480px){ 
    #news{
        padding: 30px 0;
        h2{
            font-size: 28px !important;
            line-height: 40px !important;
        }
        h4{
            font-size: 25px !important;
            line-height: 40px!important;
        }
        p{
            font-size: 20px !important;
            line-height: 20px!important;
        }
        img{
            width: 125px;
            height: 125px;
        }
    }
}