//
// project.scss
//
#project{
    transition-timing-function: cubic-bezier(.175,.885,.32,1.7);
    background-color: #FFF1F1 !important;
    color: $black !important;
    h2{
        font-size: 34px;
        font-weight: 600;
        line-height: 50px;
        padding-bottom: 75px;
    }
    p{
        font-size: 22px;
        font-weight: 500;
        line-height: 34px;
        text-align: center;
    }
}

.project-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 21px !important;
    .color-cover{
        // border-radius: 28px !important;
        padding: 5px;
        // background-image: linear-gradient(to right bottom, #FF5C30, #B0B0B0);
        img{
            width: 130px;
            height: 130px;
            // background-color: $white;
            // border-radius: 28px !important;
            overflow: hidden;
        }
    }
    .p-3{
        width: 281px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    p{
        font-weight: 500;
        font-size: 14px !important;
    }
    .project-overlay {
        position: absolute;
        background: rgba($black, 0.4);
        transform: translateY(100%);
        transition: all 0.5s;
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .project-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: 0;
            text-align: center;
            .project-icon {
                border: 1px solid $white;
                color: $white;
                height: 48px;
                width: 48px;
                line-height: 48px;
                margin: 0 auto;
                // border-radius: 50px;
            }
        }
    }
    // &:hover {
    //     .project-overlay {
    //         transform: translateY(0%);
    //         opacity: 1;
    //     }
    // }
}

@media (min-width:700px) and (max-width: 900px){ 
    #project{
        padding: 50px 0;
        p{
            width: 90% !important;
            margin: auto;
            margin-bottom: 40px !important;
        }
        .project-box{
            margin: 0;
            h2{
                font-size: 28px !important;
                line-height: 40px !important;
            }
            p{
                font-size: 20px !important;
                line-height: 30px!important;
            }img{
                width: 100px;
                height: 100px;
                background-color: $white;
                // border-radius: 28px !important;
                overflow: hidden;
            }    
        }
    }
}

@media only screen and (max-width: 480px){ 
    #project{
        padding: 30px 0;
        .project-box{
            margin-bottom: 10px;
            margin: 0 !important;
            h2{
                font-size: 28px !important;
                line-height: 40px !important;
            }
            p{
                font-size: 20px !important;
                line-height: 30px!important;
            }img{
                width: 75px;
                height: 75px;
                background-color: $white;
                // border-radius: 28px !important;
                overflow: hidden;
            }    
        }
    }
}